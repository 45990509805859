import * as React from "react"

import MenuPage from "../components/menu-page"
import Subscribe from "../components/subscribe"
import Footer from "../components/footer"
import SEO from "../components/seo"

const MailManagerPage = () => (
  <div>
      <SEO 
        title="Mail Accounts Manager" 
        description="Even though using email messages is an easy process, handling the 
        mailboxes for any web site could be a wearisome work."
    />
    <div className="sub-section-wrap">
          <MenuPage/>
    </div>

    <div className="py-12 mt-5 pb-0">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="text-center mx-auto">
                <p className="section-title"> <span className="block text-indigo-600 xl:inline">Mail Accounts</span> Manager <span role="img" aria-label="emoji">✨ </span></p>
                <p className="section-after-title">A full–fledged Mail Accounts Manager at your disposal</p>
                <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-center">
                  <div className="mt-3 sm:mt-0 sm:ml-3">
                      <a 
                      href="https://demo.hepsia.com/mail/?auto_login=true" 
                      rel="noreferrer" 
                      target="_blank" 
                      className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 md:py-4 md:text-lg md:px-10">
                      View Demo
                      </a>
                  </div>
              </div>
              <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-center">
                  <div className="mt-3 sm:mt-0 sm:ml-3">
                    <p className="m-0">
                        <img src="https://sheephostingbay.duoservers.com/template2/img/email-manager-banner.webp" alt="Sheephostingbay Web control panel"/>
                    </p>
                  </div>
              </div>
              
            </div>
        </div>
        
    </div>

    <div className="py-12 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="text-center mx-auto">
                 <p className="section-after-title">
                 <span role="img" aria-label="emoji">🦄</span> Even though using email messages is an easy process, handling the 
                 mailboxes for any web site could be a wearisome work. If you don’t use a simple to use interface, such as our 
                 Mail Accounts Manager that will help complete the task. It has a considerable amount of incorporated 
                 advantageous tools that will help carry out advanced things within a few clicks. Check out what our 
                 Mail Accounts Manager is able to do for YOU!
                </p>
              
            </div>
        </div>
        
    </div>

    <div className="py-32 server-bg">
      <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="overflow-hidden bg-white shadow-xl dark:bg-gray-800 sm:rounded-lg">
              <div>
                  <div className="grid grid-cols-1 bg-gray-200 bg-opacity-25 dark:bg-gray-800 md:grid-cols-2">
                        <div className="p-6 col-span-2 border-t border-gray-200 dark:border-gray-700 md:border-t-1">
                            <div className="sm:flex items-center">
                                <img src="https://sheephostingbay.duoservers.com/template2/img/anti-spam.webp" className="w-60" alt="Anti–Spam" />
                                <div className="ml-4 text-lg leading-7 text-gray-600 dark:text-gray-200">
                                    <p className="font-semibold p-0 m-0">Anti–Spam</p>
                                    <div className="mt-2 text-sm text-gray-500 dark:text-gray-400"> 
                                    Developed to hold spam away from you<br/><br/>
                                    Nobody wants unsolicited mail within the email account. This is why, we have launched a custom–made 
                                    anti–spam solution based on properly configured algorithms that sort arriving e–mail messages and 
                                    keep the junk mail away.
                                    <br/><br/>
                                    You can pick between different degrees of security, subject to what kind of junk mail messages you 
                                    receive. Also, it is easy to designate a distinct degree of spam protection for other mail 
                                    accounts. Ultimately, you can select what will happen to emails marked as spam – to be 
                                    erased or forwarded to a certain mailbox.
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="p-6 col-span-2 border-t border-gray-200 dark:border-gray-700 md:border-t-1">
                            <div className="sm:flex items-center">
                                <div className="ml-4 text-lg leading-7 text-gray-600 dark:text-gray-200">
                                    <p className="font-semibold p-0 m-0">Email Forwarding</p>
                                    <div className="mt-2 text-sm text-gray-500 dark:text-gray-400"> 
                                    Divert messages from one mailbox to a new one<br/><br/>
                                    We have made it easy for one to forward emails from a mailbox to an alternative one inside your website 
                                    hosting account. All you need to do is select the email you’d like to have to forward and afterwards 
                                    fill in the desired mailbox in which you wish each of the messages to get delivered to.
                                    <br/><br/>
                                    You can even allow a duplicate of every forwarded message to be kept in the email account that you 
                                    currently have forwarded.
                                    </div>
                                </div>
                                <img src="https://sheephostingbay.duoservers.com/template2/img/email-forwarding.webp" className="w-60" alt="Email Forwarding" />
                            </div>
                        </div>
                      
                        <div className="p-6 col-span-2 border-t border-gray-200 dark:border-gray-700 md:border-t-1">
                            <div className="sm:flex items-center">
                                <img src="https://sheephostingbay.duoservers.com/template2/img/email-filters.webp" className="w-60" alt="Email Filters" />
                                <div className="ml-4 text-lg leading-7 text-gray-600 dark:text-gray-200">
                                    <p className="font-semibold p-0 m-0">Email Filters</p>
                                    <div className="mt-2 text-sm text-gray-500 dark:text-gray-400"> 
                                    Bring order to your mailbox<br/><br/>
                                    Using the Mail Accounts Manager of one’s Web Control Panel, it is definitely effortless to create 
                                    new email filters. Everything you need to actually do is state the keywords that’ll be applied to 
                                    filter messages and precisely where the filter will look for them (subject, body, etc.) and then 
                                    select the measures which will be applied to the filtered emails.
                                    </div>
                                </div>
                            </div>
                        </div>   

                        <div className="p-6 col-span-2 border-t border-gray-200 dark:border-gray-700 md:border-t-1">
                            <div className="sm:flex items-center">
                                <div className="ml-4 text-lg leading-7 text-gray-600 dark:text-gray-200">
                                    <p className="font-semibold p-0 m-0">Email Auto–Configure Solution</p>
                                    <div className="mt-2 text-sm text-gray-500 dark:text-gray-400"> 
                                    Create a mailbox on your computer with a click<br/><br/>
                                    When you check your e–mail from your notebook or personal computer, it is likely that you use an e–mail 
                                    client. To save you the hassle of needing to manually include each new email account in your mail 
                                    client, we’ve introduced smart ’auto–configure’ files. Simply get hold of the file and open it up 
                                    with your mail app – this will automatically create your a mailbox inside your favored desktop 
                                    client.
                                    <br/><br/>
                                    We’ve got auto–configure files for: Mozilla Thunderbird, Mac Mail, Outlook plus Outlook Express.
                                    </div>
                                </div>
                                <img src="https://sheephostingbay.duoservers.com/template2/img/email-auto-configure.webp" className="w-60" alt="Email Auto–Configure Solution" />
                            </div>
                        </div>  

                        <div className="p-6 col-span-2 border-t border-gray-200 dark:border-gray-700 md:border-t-1">
                            <div className="sm:flex items-center">
                                <img src="https://sheephostingbay.duoservers.com/template2/img/webmail.webp" className="w-60" alt="Webmail" />
                                <div className="ml-4 text-lg leading-7 text-gray-600 dark:text-gray-200">
                                    <p className="font-semibold p-0 m-0">Webmail</p>
                                    <div className="mt-2 text-sm text-gray-500 dark:text-gray-400"> 
                                    Offered on any system, at any place<br/><br/>
                                    In case you do not have access to a mail client, you can quickly access your email by using Sheephostingbay’s 
                                    email client – Roundcube. There’s only 1 link you have to recall, and you can now look at it using any kind 
                                    of web–connected gadget from any location!
                                    <br/><br/>
                                    You may also directly sign in through your Web Control Panel, without having to provide virtually any email 
                                    login credentials.
                                    </div>
                                </div>
                            </div>
                        </div>  
                  </div>
              </div>
          </div>
      </div>
    </div>

    <Subscribe/>
    <Footer/>
  </div>
)

export default MailManagerPage
